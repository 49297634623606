import React, { useEffect, useState } from "react";
import greyflower from "../images/greyflower.gif";
import Countdown from "react-countdown";
import "../styles/limitedAccess.scss";

const LimitedAccess: React.FC = () => {
  const [showCountdown, setShowCountdown] = useState<boolean>(false);
  const [showPasswordInput, setShowPasswordInput] = useState<boolean>(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setPassword(event.target.value);
  };

  const handleSubscribe = () => {
    // Handle subscription logic here
    console.log(`Subscribing email: ${email}`);
  };

  const handleSubmit = () => {
    // Handle subscription logic here
    if (password === "123") {
      setShowCountdown(true);
    }
  };

  const handleEnterPassword = () => {
    setShowPasswordInput(true);
  }

  const handlePasswordInputKeydown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSubmit();
    }
  }

  const now = () => {
    return Date.now()
  }

  return (
    <>
      <div className="limited-access-container">
        <img className="flower-image" src={greyflower} alt="flower" />
        <div className="countdown-container">
            { showCountdown ? 
                <Countdown 
                  className="countdown" 
                  date={new Date(2023, 3, 10)} 
                  autoStart={true}
                  intervalDelay={0}
                  precision={3}
                  now={now}
                /> :
                (showPasswordInput ?
                  <>
                    <input
                      className="password-input"
                      type="password"
                      value={password}
                      onChange={handlePasswordChange} 
                      onKeyDown={handlePasswordInputKeydown}
                    />
                    <button className="button-style" onClick={handleSubmit}>
                        {">"}
                    </button></>
                :
                <p className="enter-password" onClick={handleEnterPassword}>ENTER PASSWORD</p>)
            }   
        </div>
        { showPasswordInput ? null : 
        <div className="email-container">
          <input
            className="email-input"
            type="email"
            placeholder="Your email address"
            value={email}
            onChange={handleEmailChange}
          />
          <button className="email-button-style" onClick={handleSubscribe}>
            SUBSCRIBE
          </button>
        </div>
        }
      </div>
    </>
  );
};

export default LimitedAccess;
